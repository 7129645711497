import axios from "axios";
var appurl = "/";
var url = "/api/";
// var url = 'https://localhost:5001/api/'
// var url = 'https://blockchainsandbox.elachain.cz/api/'
axios.defaults.withCredentials = true;

export default {
  async login(username, password) {
    return await axios
      .post(url + "admin/user/login", { email: username, password: password })
      .then((e) => {
        return e.data;
      })
      .catch((e) => {
        return false;
      });
  },
  async loginGoogle(token) {
    return await axios
      .post(url + "admin/user/login/google", { token: token })
      .then((e) => {
        return e.data;
      })
      .catch((e) => {
        return false;
      });
  },
  async login2FAstep1(username, password) {
    return await axios
      .post(url + "admin/user/login/2FA", {
        email: username,
        password: password,
      })
      .then((e) => {
        return e.data;
      })
      .catch((e) => {
        return false;
      });
  },
  async login2FAstep2(code) {
    return await axios
      .post(url + "admin/user/login/2FA/" + code)
      .then((e) => {
        return e.data;
      })
      .catch((e) => {
        return false;
      });
  },
  async logout() {
    return await axios
      .post(url + "admin/user/logout")
      .then((e) => {
        return true;
      })
      .catch((e) => {
        return false;
      });
  },
  async isLogged() {
    return await axios
      .get(url + "admin/user/islogged")
      .then((e) => {
        return e.data;
      })
      .catch((e) => {
        return false;
      });
  },
  async nodeId() {
    return await axios
      .get(url + "nodeId")
      .then((e) => {
        return e.data;
      })
      .catch((e) => {
        return false;
      });
  },
  async logosAvailable() {
    return await axios
      .get(url + "logosExists")
      .then((e) => {
        return e.data;
      })
      .catch((e) => {
        return false;
      });
  },
  async myNewestEntries() {
    return await axios
      .get(url + "dashboard/records/my/newest")
      .then((e) => {
        for (var i = 0; i < e.data.length; i++) {
          e.data[i].link =
            "<div hidden>" +
            e.data[i].name +
            "</div><a href='" +
            appurl +
            "#/entry/" +
            e.data[i].id +
            "'>" +
            e.data[i].name +
            "</a>";
        }
        return e.data;
      })
      .catch((e) => {
        return false;
      });
  },
  async myNewestApproved() {
    return await axios
      .get(url + "dashboard/records/approved/newest")
      .then((e) => {
        for (var i = 0; i < e.data.length; i++) {
          e.data[i].link =
            "<div hidden>" +
            e.data[i].name +
            "</div><a href='" +
            appurl +
            "#/entry/" +
            e.data[i].id +
            "'>" +
            e.data[i].name +
            "</a>";
        }
        return e.data;
      })
      .catch((e) => {
        return false;
      });
  },
  async getMyEntries() {
    return await axios
      .get(url + "dashboard/records/my/")
      .then((e) => {
        for (var i = 0; i < e.data.length; i++) {
          e.data[i].link =
            "<div hidden>" +
            e.data[i].name +
            "</div><a href='" +
            appurl +
            "#/entry/" +
            e.data[i].id +
            "'>" +
            e.data[i].name +
            "</a>";
        }
        return e.data;
      })
      .catch((e) => {
        return false;
      });
  },
  async getMyApprovedEntries() {
    return await axios
      .get(url + "dashboard/records/approved/")
      .then((e) => {
        for (var i = 0; i < e.data.length; i++) {
          e.data[i].link =
            "<div hidden>" +
            e.data[i].name +
            "</div><a href='" +
            appurl +
            "#/entry/" +
            e.data[i].id +
            "'>" +
            e.data[i].name +
            "</a>";
        }
        return e.data;
      })
      .catch((e) => {
        return false;
      });
  },
  async getDetail(id) {
    return await axios
      .get(url + "record/view/" + id)
      .then((e) => {
        return e.data;
      })
      .catch((e) => {
        return false;
      });
  },
  async confirm(data) {
    return await axios
      .post(url + "record/confirm", data)
      .then((e) => {
        for (var i = 0; i < e.data.length; i++) {
          e.data[i].link =
            "<div hidden>" +
            e.data[i].name +
            "</div><a href='" +
            appurl +
            "#/entry/" +
            e.data[i].id +
            "'>" +
            e.data[i].name +
            "</a>";
        }
        return e.data;
      })
      .catch((e) => {
        return false;
      });
  },
  async queryPublic(data) {
    return await axios
      .post(url + "record/querry", data)
      .then((e) => {
        for (var i = 0; i < e.data.length; i++) {
          e.data[i].link =
            "<div hidden>" +
            e.data[i].name +
            "</div><a href='" +
            appurl +
            "#/entry/" +
            e.data[i].id +
            "'>" +
            e.data[i].name +
            "</a>";
        }
        return e.data;
      })
      .catch((e) => {
        return false;
      });
  },
  async hfcLogin() {
    return await axios
      .post(url + "secured/hfc-login")
      .then((e) => {
        return true;
      })
      .catch((e) => {
        return false;
      });
  },
  async query(data) {
    return await axios
      .post(url + "secured/record/querry", data)
      .then((e) => {
        for (var i = 0; i < e.data.length; i++) {
          e.data[i].link =
            "<div hidden>" +
            e.data[i].name +
            "</div><a href='" +
            appurl +
            "#/entry/" +
            e.data[i].id +
            "'>" +
            e.data[i].name +
            "</a>";
        }
        return e.data;
      })
      .catch((e) => {
        return false;
      });
  },
  async createNewRecord(record) {
    return await axios.post(url + "secured/record/add", record).then((e) => {
      return e.data;
    });
  },
  async viewToApprove(id, pin) {
    return await axios
      .get(url + "secured/record/view/" + id + "/" + pin)
      .then((e) => {
        return e.data;
      })
      .catch((e) => {
        return false;
      });
  },
  async approve(id, pin, data) {
    return await axios
      .post(url + "secured/record/view/" + id + "/" + pin, data)
      .then((e) => {
        return e.data;
      })
      .catch((e) => {
        return false;
      });
  },
  // do not use - NOT impented right now.. might be moved to admin services in the future
  /*
  async getNodes () {
    return await axios.get(url + 'master/nodes/').then(e => {
      return e.data
    }).catch(e => { return false })
  },
  */
};
