<template>
  <div>
    <form ref="fileform">
      <small
        class="font-weight-bold ml-4"
        v-text="$ml.get('detail_files')"
      ></small>
      <div class="row upload-drop-zone border-primary m-1" id="drop-zone">
        <label class="file-select col-2 mb-0 bg-subprimary rounded-left">
          <div class="pt-4 pb-4 font-weight-bold">
            <span v-text="$ml.get('landingpage_via_files')"></span><br />
            <font-awesome-icon
              icon="file-upload"
              class="text-primary mt-3 mb-2"
              style="font-size: 4rem"
            ></font-awesome-icon
            ><br />
            <small class="text-muted" v-text="'(.pdf, .jpg, .png)'"></small>
          </div>
          <!-- Now, the file input that we hide. -->
          <input class="d-none" type="file" @change="handleFileChange" />
        </label>

        <div class="col-10 mt-2">
          <div
            v-if="this.hashes.length == 0"
            class="h-100 d-flex flex-column justify-content-around"
          >
            <span
              class="text-muted font-semibold"
              v-text="$ml.get('no_file_selected')"
            ></span>
          </div>
          <file-zone
            :removable="this.removable"
            :url_editable="this.url_editable"
            :hashes="this.hashes"
            @remove_file="removeFile"
          ></file-zone>
        </div>
      </div>
      <div class="pb-5 d-flex justify-content-between">
        <div>
          <small
            class="pl-3 text-muted"
            v-text="$ml.get('landingpage_via_files_text_2')"
          ></small>
        </div>
        <div>
          <a
            v-if="!hashcode_active"
            class="pr-2 btn btn-sm btn-outline-primary"
            @click="hashcode_active = true"
            v-text="$ml.get('landingpage_via_files_text_1')"
          ></a>
          <a
            v-if="hashcode_active"
            class="btn btn-link text-danger"
            @click="hashcode_active = false"
            ><font-awesome-icon icon="times-circle"></font-awesome-icon
          ></a>
        </div>
      </div>
      <div v-if="hashcode_active" class="row px-3">
        <div class="col-7">
          <b-form-group
            id="fieldset-1"
            :state="state_hash"
            :label="$ml.get('create_select_hashes_hash_title')"
            label-for="add_hash"
            :description="$ml.get('hash_info')"
          >
            <b-form-input
              ref="add_hash"
              id="add_hash"
              v-model="add_hash"
              :placeholder="$ml.get('create_select_hashes_hash_placeholder')"
              trim
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-2">
          <a @click="addHash" class="mt-4 btn btn-outline-primary"
            ><font-awesome-icon icon="plus" class="mr-2" /><span>{{
              $ml.get("create_select_file_add")
            }}</span></a
          >
        </div>
      </div>
    </form>
  </div>
</template>
<style>
.upload-drop-zone {
  border-width: 2px;
  margin-bottom: 20px;
  border-style: dashed;
  padding: 1px;
  border-radius: 0.75rem;
  text-align: center;
}

.card-file {
  border-width: 0;
  border-left-width: 10px;
}

.file-select {
  cursor: pointer;
}
</style>
<script>
import FileZone from "./FileZone";
export default {
  components: { FileZone },
  props: {
    removable: {
      type: Boolean,
      default: true,
    },
    url_editable: {
      type: Boolean,
      default: true,
    },
    hashes: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  name: "drag-drop-file",

  computed: {
    state_hash() {
      return this.add_hash_empty;
    },
    state_name() {
      return this.add_name_empty;
    },
  },
  methods: {
    removeFile(id) {
      this.hashes = this.hashes.filter(function (value, index, arr) {
        return value.documentHash !== id;
      });
      this.$emit("hashes_change", this.hashes);
    },
    addHash() {
      var ex = false;
      if (this.add_hash.length !== 64) {
        this.$refs.add_hash.$el.classList.add("border-danger");
        ex = true;
      } else {
        this.$refs.add_hash.$el.classList.remove("border-danger");
      }
      if (ex) {
        return;
      }
      this.hashes.push({
        fileName: this.add_name,
        documentHash: this.add_hash,
        hashFunction: "SHA-256",
        documentUrl: "",
        timestamp: "",
      });
      this.add_hash = "";
      this.add_name = "";
      this.$emit("hashes_change", this.hashes);
    },
    hexString(buffer) {
      const byteArray = new Uint8Array(buffer);

      const hexCodes = [...byteArray].map((value) => {
        const hexCode = value.toString(16);
        const paddedHexCode = hexCode.padStart(2, "0");
        return paddedHexCode;
      });

      return hexCodes.join("");
    },
    handleFileChange(e) {
      for (var i = 0; i < e.target.files.length; i++) {
        this.readFile(e.target.files[i]);
      }
      e.target.value = "";
    },
    readFile(fd) {
      var reader = new FileReader();
      var tthis = this;
      reader.onloadend = function (e) {
        window.crypto.subtle.digest("SHA-256", reader.result).then((dig) => {
          tthis.hashes.push({
            fileName: fd.name,
            documentHash: tthis.hexString(dig),
            hashFunction: "SHA-256",
            timestamp: fd.lastModified + "",
          });
          tthis.$emit("hashes_change", tthis.hashes);
        });
      };
      reader.readAsArrayBuffer(fd);
    },
  },
  mounted() {
    [
      "drag",
      "dragstart",
      "dragend",
      "dragover",
      "dragenter",
      "dragleave",
      "drop",
    ].forEach(
      function (evt) {
        this.$refs.fileform.addEventListener(
          evt,
          function (e) {
            e.preventDefault();
            e.stopPropagation();
          },
          false
        );
      }.bind(this)
    );

    /*
        Add an event listener for drop to the form
      */
    this.$refs.fileform.addEventListener(
      "drop",
      function (e) {
        /*
          Capture the files from the drop event and add them to our local files
          array.
        */
        for (let i = 0; i < e.dataTransfer.files.length; i++) {
          this.readFile(e.dataTransfer.files[i]);
        }
      }.bind(this)
    );
  },
  data() {
    return {
      hashcode_active: false,
      add_hash: "",
      add_name: "",
    };
  },
};
</script>
