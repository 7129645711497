var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"component mt-3 px-4"},[_c('div',{staticClass:"card bg-white rounded-0 border-0"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"text-right"},[_c('a',{staticClass:"btn btn-outline-primary text-white",domProps:{"textContent":_vm._s(_vm.$ml.get('compare'))},on:{"click":function($event){_vm.showCompare = !_vm.showCompare}}}),(
            this.$route.params.pin != null &&
            this.entry.approveStatus == 'waitingForApproval'
          )?_c('a',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-comment-confirm",modifiers:{"modal-comment-confirm":true}}],staticClass:"btn btn-outline-primary text-white ml-2",domProps:{"textContent":_vm._s(_vm.$ml.get('confirm'))}}):_vm._e()]),(this.showCompare)?_c('drag-drop-file',{attrs:{"url_editable":false,"hashes":_vm.fullHashes},on:{"hashes_change":_vm.hashesChange}}):_vm._e(),_c('small',{staticClass:"text-muted font-semibold"},[_c('span',{domProps:{"textContent":_vm._s(_vm.$ml.get('detail_timestamp'))}}),_vm._v(" : "),_c('span',{domProps:{"textContent":_vm._s(_vm.formatDate(_vm.entry.timestamp))}}),_vm._v(" ("),_c('span',{domProps:{"textContent":_vm._s(_vm.$ml.get(_vm.entry.private ? 'entry_private' : 'entry_public'))}}),_vm._v(")")]),_c('h1',{staticClass:"font-weight-bold",domProps:{"textContent":_vm._s(_vm.entry.name)}}),_c('div',{staticClass:"font-semibold"},[(_vm.entry.private)?_c('span',{staticClass:"text-primary"},[_c('font-awesome-icon',{attrs:{"icon":"lock"}}),_c('span',{domProps:{"textContent":_vm._s(_vm.$ml.get('entry_private'))}})],1):_c('span',{staticClass:"text-primary"},[_c('font-awesome-icon',{attrs:{"icon":"lock-open"}}),_c('span',{domProps:{"textContent":_vm._s(_vm.$ml.get('entry_public'))}})],1),_c('span',{staticClass:"ml-3"},[_c('span',{domProps:{"textContent":_vm._s(_vm.$ml.get('entry_id') + ':')}}),_c('span',{staticClass:"bg-semidark p-1 rounded",domProps:{"textContent":_vm._s(_vm.entry.id)}})]),_c('span',{staticClass:"ml-3 text-success"},[_c('font-awesome-icon',{attrs:{"icon":"circle"}}),_c('span',{domProps:{"textContent":_vm._s(_vm.$ml.get('detail_active'))}})],1),(
            _vm.entry.expirationDate != null && _vm.entry.expirationDate.length > 0
          )?_c('span',{staticClass:"text-muted",domProps:{"textContent":_vm._s(
            '(' +
            _vm.$ml.get('expires') +
            ' ' +
            _vm.formatDate(_vm.entry.expirationDate) +
            ')'
          )}}):_vm._e()]),_c('div',{staticClass:"mt-4 text-muted font-semibold pb-3",domProps:{"textContent":_vm._s(_vm.$ml.get('detail_files'))}}),_c('file-zone',{attrs:{"removable":false,"url_editable":false,"hashes":_vm.entry.hashes,"compareHashes":_vm.compareHashes}}),_c('b-row',{staticClass:"mt-5"},[_c('b-col',{attrs:{"sm":"6"}},[_c('small',{staticClass:"font-semibold text-muted",domProps:{"textContent":_vm._s(_vm.$ml.get('detail_author'))}}),_c('br'),_c('span',{staticClass:"large",domProps:{"textContent":_vm._s(
              !_vm.entry.parentOrgId || _vm.entry.parentOrgId === _vm.entry.orgId
                ? _vm.entry.authorId + ' / ' + _vm.entry.orgId
                : _vm.entry.authorId +
                  ' / ' +
                  _vm.entry.parentOrgId +
                  '/' +
                  _vm.entry.orgId
            )}})]),_c('b-col',{attrs:{"sm":"6"}},[_c('small',{staticClass:"font-semibold text-muted",domProps:{"textContent":_vm._s(_vm.$ml.get('detail_node'))}}),_c('br'),_c('span',{staticClass:"large",domProps:{"textContent":_vm._s(_vm.entry.nodeId)}})])],1),_c('b-row',{staticClass:"mt-5"},[_c('b-col',{attrs:{"sm":"6"}},[_c('small',{staticClass:"font-semibold text-muted",domProps:{"textContent":_vm._s(_vm.$ml.get('detail_eid1'))}}),_c('br'),_c('span',{staticClass:"large",domProps:{"textContent":_vm._s(_vm.entry.externalId1)}}),_c('span',{staticClass:"text-secondary",domProps:{"textContent":_vm._s('(' + _vm.entry.externalId1Meaning + ')')}})]),_c('b-col',{attrs:{"sm":"6"}},[_c('small',{staticClass:"font-semibold text-muted",domProps:{"textContent":_vm._s(_vm.$ml.get('detail_eid2'))}}),_c('br'),_c('span',{staticClass:"large",domProps:{"textContent":_vm._s(_vm.entry.externalId2)}}),_c('span',{staticClass:"text-secondary",domProps:{"textContent":_vm._s('(' + _vm.entry.externalId2Meaning + ')')}})])],1),_c('div',{staticClass:"mt-5"},[_c('small',{staticClass:"text-muted font-semibold",domProps:{"textContent":_vm._s(_vm.$ml.get('detail_anotation'))}})]),_c('p',{domProps:{"textContent":_vm._s(_vm.entry.annotation)}}),_c('div',{staticClass:"mt-5"},[_c('small',{staticClass:"text-muted font-semibold",domProps:{"textContent":_vm._s(_vm.$ml.get('detail_comment_process'))}})]),_c('p',{domProps:{"textContent":_vm._s(_vm.$ml.get('approvestatus_' + this.entry.approveStatus))}}),(this.$route.params.pin == null && this.entry.approverPin)?_c('div',[_c('div',{staticClass:"mt-5"},[_c('small',{staticClass:"text-muted font-semibold",domProps:{"textContent":_vm._s(_vm.$ml.get('comment_pin'))}})]),_c('p',{staticClass:"text-danger",domProps:{"textContent":_vm._s(this.entry.approverPin)}})]):_vm._e(),_c('br'),(
          this.entry.approveStatus !== 'waitingForApproval' &&
          this.entry.approveStatus !== 'withoutApproval'
        )?_c('table',{staticClass:"table"},[_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$ml.get("approve_by")))]),(!_vm.entry.approverParentOrgId && !_vm.entry.approverOrgId)?_c('td',[_vm._v(" "+_vm._s(this.entry.approverId)+" ")]):_c('td',{domProps:{"textContent":_vm._s(
              _vm.entry.approverParentOrgId === _vm.entry.approverOrgId
                ? _vm.entry.approverId + ' / ' + _vm.entry.approverOrgId
                : _vm.entry.approverId +
                  ' / ' +
                  _vm.entry.approverParentOrgId +
                  '/' +
                  _vm.entry.approverOrgId
            )}})]),(!!_vm.entry.approveNodeId)?_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$ml.get("approve_node")))]),_c('td',[_vm._v(_vm._s(this.entry.approveNodeId))])]):_vm._e(),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$ml.get("approve_at")))]),_c('td',[_vm._v(" "+_vm._s(new Date(this.entry.approveTimestamp).toLocaleDateString("cs"))+" ")])]),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$ml.get("approve_comment")))]),_c('td',[_vm._v(_vm._s(this.entry.approveComment))])])]):_vm._e()],1)]),_c('b-modal',{ref:"modal-comment-confirm",attrs:{"id":"modal-comment-confirm","title":_vm.$ml.get('comment_t'),"hide-footer":""}},[_c('b-form-group',{attrs:{"label":_vm.$ml.get('comment')}},[_c('b-form-textarea',{attrs:{"id":"textarea","rows":"3","max-rows":"6"},model:{value:(_vm.comment_text),callback:function ($$v) {_vm.comment_text=$$v},expression:"comment_text"}})],1),_c('div',{staticClass:"text-right"},[_c('a',{staticClass:"btn btn-outline-danger",domProps:{"textContent":_vm._s(_vm.$ml.get('comment_notapproved'))},on:{"click":_vm.sendApproveN}}),_c('a',{staticClass:"btn btn-outline-warning",domProps:{"textContent":_vm._s(_vm.$ml.get('comment_approved_with'))},on:{"click":_vm.sendApproveW}}),_c('a',{staticClass:"btn btn-outline-primary",domProps:{"textContent":_vm._s(_vm.$ml.get('comment_approved'))},on:{"click":_vm.sendApprove}})])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }