<template>
  <div class="hello">
    <div v-if="isLogged" class="mt-3 px-4">
      <h1 class="font-weight-bold" v-text="$ml.get('dashboard_title')"></h1>
      <div class="row mt-5 text-center">
        <div class="col">
          <a class="btn px-4 py-2 btn-primary text-white" href="#/search">
            <font-awesome-icon class="f-18" icon="search" />
            <span v-text="$ml.get('dashboard_search')"></span>
          </a>
        </div>
        <div class="col border-left border-dark">
          <a class="btn px-4 py-2 btn-success text-white" href="#/create/entry">
            <font-awesome-icon class="f-18" icon="plus" />
            <span v-text="$ml.get('dashboard_create')"></span>
          </a>
        </div>
        <div class="col border-left border-dark">
          <a
            class="btn px-4 py-2 btn-primary text-white"
            href="javascript:void(0)"
            v-b-modal.modal-comment
          >
            <font-awesome-icon class="f-18" icon="gavel" />
            <span v-text="$ml.get('dashboard_comment')"></span>
          </a>
        </div>
      </div>
      <div class="card rounded-0 border-0 shadow bg-white mt-5">
        <div class="card-body mb-3">
          <h3
            class="mb-5 font-weight-bold"
            v-text="$ml.get('sidebar_my_entries')"
          ></h3>
          <entry-table :items="this.newest_entries"></entry-table>
        </div>
      </div>
      <div class="card rounded-0 border-0 shadow bg-white mt-5 mb-5">
        <div class="card-body mb-3">
          <h3
            class="mb-5 font-weight-bold"
            v-text="$ml.get('sidebar_my_commented_entries')"
          ></h3>
          <entry-table :items="this.newest_approved_entries"></entry-table>
        </div>
      </div>
      <div class="mt-5 mb-5"></div>
    </div>
    <div class="container pt-5 mt-5" v-else>
      <h1 class="text-xxx-large" v-text="$ml.get('landingpage_title')"></h1>
      <h1
        class="font-weight-bold text-xxx-large"
        v-text="$ml.get('landingpage_title2')"
      ></h1>
      <div class="pt-4 pb-5">
        <span class="text-large" v-text="$ml.get('landingpage_text')"></span
        ><!--<font-awesome-icon icon="arrow-right" class="ml-4 text-primary"></font-awesome-icon>  <a href="https://www.elachain.cz/dokumentace/doku.php?id=o_aplikaci" class="link-underline">Více o službě</a>-->
      </div>
      <div class="row text-center">
        <div class="col-sm-12 col-md-5">
          <drag-drop-file-sec @hashes_change="filesChange"></drag-drop-file-sec>
        </div>
        <div class="col-md-1 col-sm-12 align-self-center">
          <p v-text="$ml.get('or')" />
        </div>
        <div class="col-sm-12 col-md-5 col">
          <div class="card border-stripped">
            <div class="card-body">
              <h5 v-text="$ml.get('landingpage_via_hash')"></h5>
              <b-form-textarea
                id="textarea"
                v-model="doc_id"
                placeholder="DOC_a93714bf-3fc1-3e1a-c15e-831fae135af5"
                rows="3"
                max-rows="6"
                style="overflow-y: hidden; text-align: center"
              ></b-form-textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center mt-4 mb-4">
        <a
          @click="search"
          class="btn btn-primary p-3 pl-2 pr-5 btn-lg text-white"
          ><font-awesome-icon icon="search" class="mr-5" /><span>{{
            $ml.get("search_entry")
          }}</span></a
        >
      </div>
      <span v-text="$ml.get('landingpage_text_1')"></span>
      <a
        class="btn btn-link p-0 pb-1 link-underline"
        href="https://www.blockchainotarius.cz"
        v-text="$ml.get('here') + '.'"
      ></a
      ><br />
      <span v-text="$ml.get('langingpage_text_2')"></span>
      <b-button
        v-b-modal.modal-1
        class="p-0 pb-1 link-underline"
        variant="link"
        v-text="$ml.get('login')"
      ></b-button>
    </div>
  </div>
</template>

<script>
import DragDropFileSec from "./DragDropFileSec";
import { mapGetters, mapActions } from "vuex";

import EntryTable from "./Table";
export default {
  components: { EntryTable, DragDropFileSec },
  name: "hello",
  computed: mapGetters([
    "isLogged",
    "newest_approved_entries",
    "newest_entries",
  ]),
  data() {
    return {
      files: [],
      doc_id: "",
      files_calc: [],
    };
  },
  created() {},
  methods: {
    ...mapActions([
      "set_last_query",
      "set_query_header",
      "set_last_query_hashes",
    ]),
    search() {
      if (this.doc_id.length > 0) {
        this.backend.queryPublic({ id: this.doc_id }).then((e) => {
          this.set_last_query(e);
          this.set_last_query_hashes([]);
          this.set_query_header("search_results");
          this.$router.push({ path: "/result" });
        });
      } else {
        this.backend
          .queryPublic({
            documentHash: this.files.map((file) => file.documentHash),
          })
          .then((e) => {
            this.set_last_query(e);
            this.set_last_query_hashes(
              this.files.map((file) => file.documentHash)
            );
            this.set_query_header("confirmed_results");
            this.$router.push({ path: "/result" });
          });
      }
    },
    filesChange(hashes) {
      this.files = hashes;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.icon-large {
  font-size: 5rem;
}

h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

.hidden {
  display: none;
}

.hash {
  word-break: break-all !important;
}

[contentEditable="true"]:empty:not(:focus)::before {
  content: attr(data-placeholder);
  text-align: center;
  text-decoration: underline;
  font-size: 2rem;
}

.front-textarea {
  text-align: center;
  border: 0 !important;
}
</style>
