// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import axios from "axios";
import backend from "./backend";
import BootstrapVue from "bootstrap-vue";
import App from "./App";
import router from "./router";
import "./ml";
import Vidle from "v-idle";
import VueCookieAcceptDecline from "vue-cookie-accept-decline";
// import './assets/custom.scss'

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faFileUpload,
  faTimes,
  faExchangeAlt,
  faTimesCircle,
  faLock,
  faLockOpen,
  faClock,
  faSearch,
  faArrowRight,
  faHome,
  faPlus,
  faGavel,
  faCircle,
  faCheck,
  faCross,
  faMinus,
  faQuestion,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import store from "./store";

library.add(
  faFileUpload,
  faLock,
  faExchangeAlt,
  faTimesCircle,
  faLockOpen,
  faTimes,
  faClock,
  faArrowRight,
  faSearch,
  faHome,
  faPlus,
  faGavel,
  faCircle,
  faCheck,
  faCross,
  faMinus,
  faQuestion,
  faSignOutAlt
);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("vue-cookie-accept-decline", VueCookieAcceptDecline);
Vue.use(Vidle);
Vue.use(BootstrapVue);
Vue.config.productionTip = false;
Vue.prototype.$http = axios;
Vue.prototype.backend = backend;

/* eslint-disable no-new */
new Vue({
  el: "#app",
  router,
  store,
  template: "<App/>",
  components: { App },
});
