import Vue from "vue";
import Router from "vue-router";
import Hello from "@/components/Hello";
import CreateEntry from "@/components/CreateEntry";
import Search from "@/components/Search";
import SearchResult from "@/components/SearchResult";
import SearchResultPublic from "@/components/SearchResultPublic";
import MyEntries from "@/components/MyEntries";
import MyCommentedEntries from "@/components/MyCommentedEntries";
import Detail from "@/components/Detail";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      name: "Hello",
      component: Hello,
    },
    {
      path: "/create/entry",
      name: "CreateEntry",
      component: CreateEntry,
    },
    {
      path: "/search",
      name: "Search",
      component: Search,
    },
    {
      path: "/search/result",
      name: "SearchResult",
      component: SearchResult,
    },
    {
      path: "/result",
      name: "SearchResultPublic",
      component: SearchResultPublic,
    },
    {
      path: "/my/entries",
      name: "MyEntries",
      component: MyEntries,
    },
    {
      path: "/my/commentedentries",
      name: "MyCommentedEntries",
      component: MyCommentedEntries,
    },
    {
      path: "/entry/:id",
      name: "Detail",
      component: Detail,
    },
    {
      path: "/entry/:id/:pin",
      name: "Detail approval",
      component: Detail,
    },
  ],
});
