<template>
  <div class="component mt-3 px-4">
    <div class="card bg-white border-0 rounded-0">
      <div class="card-body">
        <h1 v-text="$ml.get('mycommentedentries_title')"></h1>
        <entry-table :items="this.g_entries_my_approved"></entry-table>
      </div>
    </div>
  </div>
</template>
<script>
import EntryTable from "./Table";
import { mapGetters } from "vuex";
export default {
  components: { EntryTable },
  computed: mapGetters(["g_entries_my_approved"]),
};
</script>
<style></style>
