import Vue from "vue";
import Vuex from "vuex";
import backend from "./backend";

Vue.use(Vuex);

function storageAvailable(type) {
  var storage;
  try {
    storage = window[type];
    var x = "__storage_test__";
    storage.setItem(x, x);
    storage.removeItem(x);
    return storage;
  } catch (e) {
    return null;
  }
}

const state = {
  logged: null,
  fullname: null,
  email: null,
  organization: null,
  parentOrganization: null,
  nodes: [],
  dashboard_newest: [],
  dashboard_approved_newest: [],
  entries_my: [],
  entries_my_approved: [],
  last_query: [],
  last_query_hashes: [],
  query_header: "",
  session: storageAvailable("sessionStorage"),
};
const mutations = {
  login(state, user) {
    state.logged = true;
    state.fullname = user.fullname;
    state.email = user.email;
    state.organization = user.orgId;
    state.parentOrganization = user.parentOrgId;
  },
  logout(state) {
    state.logged = false;
    state.fullname = null;
    state.email = null;
    state.organization = null;
    state.parentOrganization = null;
    state.dashboard_newest = [];
    state.dashboard_approved_newest = [];
    state.entries_my = [];
    state.entries_my_approved = [];
    if (state.session) {
      state.session.removeItem("dashboard_newest");
      state.session.removeItem("dashboard_approved_newest");
      state.session.removeItem("entries_my");
      state.session.removeItem("entries_my_approved");
      state.session.removeItem("nodes");
    }
  },
  newest(state, newest) {
    state.dashboard_newest = newest;
    if (state.session) {
      state.session.setItem("dashboard_newest", JSON.stringify(newest));
    }
  },
  newest_approved(state, newest) {
    state.dashboard_approved_newest = newest;
    if (state.session) {
      state.session.setItem(
        "dashboard_approved_newest",
        JSON.stringify(newest)
      );
    }
  },
  m_entries_my(state, newest) {
    state.entries_my = newest;
    if (state.session) {
      state.session.setItem("entries_my", JSON.stringify(newest));
    }
  },
  m_entries_my_approved(state, newest) {
    state.entries_my_approved = newest;
    if (state.session) {
      state.session.setItem("entries_my_approved", JSON.stringify(newest));
    }
  },
  m_nodes(state, nodes) {
    state.nodes = nodes;
    if (state.session) {
      state.session.setItem("nodes", JSON.stringify(nodes));
    }
  },
  m_last_query(state, last) {
    state.last_query = last;
    if (state.session) {
      state.session.setItem("last_query", JSON.stringify(last));
    }
  },
  m_last_query_hashes(state, hashes) {
    state.last_query_hashes = hashes;
    if (state.session) {
      state.session.setItem("last_query_hashes", JSON.stringify(hashes));
    }
  },
  m_query_header(state, last) {
    state.query_header = last;
    if (state.session) {
      state.session.setItem("query_header", JSON.stringify(last));
    }
  },
};

const actions = {
  login: ({ commit }, user) => {
    commit("login", user);
  },
  set_newest: ({ commit }, newest) => {
    commit("newest", newest);
  },
  set_approved_newest: ({ commit }, newest) => {
    commit("newest_approved", newest);
  },
  set_entries_my: ({ commit }, newest) => {
    commit("m_entries_my", newest);
  },
  set_entries_my_approved: ({ commit }, newest) => {
    commit("m_entries_my_approved", newest);
  },
  logout: ({ commit }) => commit("logout"),
  set_nodes: ({ commit }, newest) => {
    commit("m_nodes", newest);
  },
  set_last_query: ({ commit }, newest) => {
    commit("m_last_query", newest);
  },
  set_last_query_hashes: ({ commit }, hashes) => {
    commit("m_last_query_hashes", hashes);
  },
  set_query_header: ({ commit }, querry) => {
    commit("m_query_header", querry);
  },
};

const getters = {
  isLogged: (state) => {
    if (state.logged == null) {
      backend.isLogged().then((r) => {
        if (r !== false) {
          state.logged = true;
          state.fullname = r.fullname;
          state.email = r.email;
          state.organization = r.orgId;
          state.parentOrganization = r.parentOrgId;
          if (state.session) {
            var last = state.session.getItem("nodes");
            if (last) {
              state.nodes = JSON.parse(last);
            } else {
              state.node = [];
            }

            last = state.session.getItem("dashboard_newest");
            if (last) {
              state.dashboard_newest = JSON.parse(last);
            } else {
              backend.myNewestEntries().then((e) => {
                if (e !== false) {
                  mutations.newest(state, e);
                }
              });
            }

            last = state.session.getItem("dashboard_approved_newest");
            if (last) {
              state.dashboard_approved_newest = JSON.parse(last);
            } else {
              backend.myNewestApproved().then((e) => {
                if (e !== false) {
                  mutations.newest_approved(state, e);
                }
              });
            }

            last = state.session.getItem("entries_my");
            if (last) {
              state.entries_my = JSON.parse(last);
            } else {
              backend.getMyEntries().then((e) => {
                if (e !== false) {
                  mutations.m_entries_my(state, e);
                }
              });
            }

            last = state.session.getItem("entries_my_approved");
            if (last) {
              state.entries_my_approved = JSON.parse(last);
            } else {
              backend.getMyApprovedEntries().then((e) => {
                if (e !== false) {
                  mutations.m_entries_my_approved(state, e);
                }
              });
            }
          }
          return true;
        } else {
          state.logged = false;
          return state.logged;
        }
      });
    } else {
      return state.logged;
    }
  },
  email: (state) => state.email,
  fullname: (state) => state.fullname,
  organization: (state) => state.organization,
  parentOrganization: (state) => state.parentOrganization,
  newest_entries: (state) => state.dashboard_newest,
  newest_approved_entries: (state) => state.dashboard_approved_newest,
  g_entries_my: (state) => state.entries_my,
  g_entries_my_approved: (state) => state.entries_my_approved,
  g_nodes: (state) => state.nodes,
  g_last_query: (state) => {
    if (state.last_query.length > 0 || !state.session) {
      return state.last_query;
    } else {
      if (state.session) {
        var last = state.session.getItem("last_query");
        if (last) {
          return JSON.parse(last);
        } else {
          return state.last_query;
        }
      }
    }
  },
  g_last_query_hashes: (state) => {
    if (state.last_query_hashes.length > 0 || !state.session) {
      return state.last_query_hashes;
    } else {
      if (state.session) {
        var last = state.session.getItem("last_query_hashes");
        if (last) {
          return JSON.parse(last);
        } else {
          return state.last_query_hashes;
        }
      }
    }
  },
  g_query_header: (state) => {
    if (state.query_header !== "" || !state.session) {
      return state.query_header;
    } else {
      if (state.session) {
        var last = state.session.getItem("query_header");
        if (last) {
          return JSON.parse(last);
        } else {
          return state.query_header;
        }
      }
    }
  },
  g_session: (state) => state.session,
};

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
});
