<template>
  <div class="component mt-3 px-4">
    <h1
      class="font-semibold font-weight-bold"
      v-text="$ml.get('search_title')"
    ></h1>
    <div
      class="py-4 font-semibold d-flex text-center flex-row justify-content-around"
    >
      <a
        class="search"
        v-bind:class="{ active: tab }"
        @click="tabFiles"
        href="javascript:void(0)"
        v-text="$ml.get('search_files')"
      ></a>
      <font-awesome-icon
        class="text-primary"
        icon="exchange-alt"
      ></font-awesome-icon>
      <a
        class="search"
        v-bind:class="{ active: !tab }"
        @click="tabMetadata"
        href="javascript:void(0)"
        v-text="$ml.get('search_advanced')"
      ></a>
    </div>
    <div v-if="tab" class="card bg-white border-0 shadow rounded-0">
      <div class="card-body">
        <b-form-group
          id="fieldset-1"
          :label="$ml.get('entry_id')"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="query.id"
            :placeholder="$ml.get('example') + ' 156489879466'"
            trim
          ></b-form-input>
        </b-form-group>
        <drag-drop-file
          @hashes_change="hashesChange"
          :url_editable="false"
        ></drag-drop-file>
        <div class="d-flex flex-row-reverse">
          <a class="btn btn-primary py-2 px-3 text-white" @click="send"
            ><font-awesome-icon icon="search" class="mr-2" /><span>{{
              $ml.get("search_entry")
            }}</span></a
          >
        </div>
      </div>
    </div>
    <div v-else class="card bg-white border-0 shadow rounded-0">
      <div class="card-body">
        <b-form-group
          id="fieldset-name"
          :label="$ml.get('query_name')"
          label-for="input-name"
        >
          <b-form-input
            id="input-name"
            v-model="query.name"
            :placeholder="$ml.get('query_name_p')"
            trim
          ></b-form-input>
        </b-form-group>
        <div class="row">
          <div class="col-4">
            <b-form-group
              id="fieldset-queryNode"
              :label="$ml.get('query_on_node')"
              label-for="queryNode"
            >
              <!-- <b-form-select id="queryNode" v-model="query.nodeId" text-field="item2" value-field="item1" :options="this.g_nodes"></b-form-select> -->
              <b-form-input
                id="queryNode"
                v-model="query.nodeId"
                :placeholder="$ml.get('query_on_node_p')"
                trim
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-2">
            <b-form-group
              id="fieldset-queryTimestampFrom"
              :label="$ml.get('query_from')"
              label-for="queryTimestampFrom"
            >
              <b-form-input
                id="queryTimestampFrom"
                type="date"
                v-model="query.timestampFrom"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-2">
            <b-form-group
              id="fieldset-queryTimestampTo"
              :label="$ml.get('query_to')"
              label-for="queryTimestampTo"
            >
              <b-form-input
                id="queryTimestampTo"
                type="date"
                v-model="query.timestampTo"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-2">
            <b-form-group :label="$ml.get('query_state')">
              <b-form-radio-group
                id="btn-radios-1"
                v-model="query.status"
                button-variant="outline-primary"
                size="sm"
                :options="[
                  { text: $ml.get('query_state_v'), value: 'NA' },
                  { text: $ml.get('query_state_a'), value: 'active' },
                  { text: $ml.get('query_state_n'), value: 'inactive' },
                ]"
                buttons
                name="radios-btn-default"
              ></b-form-radio-group>
            </b-form-group>
          </div>
          <div class="col-2">
            <b-form-group
              id="fieldset-approveStatus"
              :label="$ml.get('table_approval_state')"
              label-for="approveStatus"
            >
              <b-form-select
                id="queryNode"
                v-model="query.approveStatus"
                text-field="text"
                value-field="value"
                :options="this.approveOptions"
              ></b-form-select>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <b-form-group
              id="fieldset-queryAuthor"
              :label="$ml.get('query_author')"
              label-for="queryAuthor"
            >
              <b-form-input
                id="queryAuthor"
                type="text"
                :placeholder="$ml.get('query_author_p')"
                v-model="query.authorId"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group
              id="fieldset-queryOrg"
              :label="$ml.get('query_org')"
              label-for="queryOrg"
            >
              <b-form-input
                id="queryOrg"
                type="text"
                :placeholder="$ml.get('query_org_p')"
                v-model="query.orgId"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <b-form-group
              id="fieldset-queryEx1"
              :label="$ml.get('query_exid1')"
              label-for="queryEx1"
            >
              <b-form-input
                id="queryEx1"
                type="text"
                :placeholder="$ml.get('query_ex_p')"
                v-model="query.externalId1"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group
              id="fieldset-queryEx2"
              :label="$ml.get('query_exid2')"
              label-for="queryEx2"
            >
              <b-form-input
                id="queryEx2"
                type="text"
                :placeholder="$ml.get('query_ex_p')"
                v-model="query.externalId2"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <div>
          <b-form-group :label="$ml.get('query_anotation')">
            <b-form-textarea
              id="queryAnnotation"
              v-model="query.annotation"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </b-form-group>
        </div>
        <div class="d-flex flex-row-reverse">
          <a class="btn btn-primary py-2 px-3 text-white" @click="send"
            ><font-awesome-icon icon="search" class="mr-2" /><span>{{
              $ml.get("search_entry")
            }}</span></a
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DragDropFile from "./DragDropFile";
import { mapGetters, mapActions } from "vuex";
export default {
  components: { DragDropFile },
  data() {
    return {
      tab: true,
      entry_id: "",
      hashes: [],
      queryA: {
        id: "",
        authorId: "",
        name: "",
        documentHash: [],
        timestampFrom: "",
        timestampTo: "",
        status: "NA",
        nodeId: "",
        externalId1: "",
        externalId2: "",
        orgId: "",
        annotation: "",
        approveStatus: "NA",
      },
      query: {
        id: "",
        authorId: "",
        name: "",
        documentHash: [],
        timestampFrom: "",
        timestampTo: "",
        status: "NA",
        nodeId: "",
        externalId1: "",
        externalId2: "",
        orgId: "",
        annotation: "",
        approveStatus: "NA",
      },
    };
  },
  computed: {
    ...mapGetters(["g_nodes"]),
    approveOptions() {
      return [
        {
          text: this.$ml.get("query_state_v"),
          value: "NA",
        },
        {
          text: this.$ml.get("approvestatus_withoutApproval"),
          value: "withoutApproval",
        },
        {
          text: this.$ml.get("approvestatus_waitingForApproval"),
          value: "waitingForApproval",
        },
        {
          text: this.$ml.get("approvestatus_approved"),
          value: "approved",
        },
        {
          text: this.$ml.get("approvestatus_approvedWithComments"),
          value: "approvedWithComments",
        },
        {
          text: this.$ml.get("approvestatus_notApproved"),
          value: "notApproved",
        },
      ];
    },
  },
  methods: {
    ...mapActions([
      "set_last_query",
      "set_last_query_hashes",
      "set_query_header",
    ]),
    tabFiles() {
      this.tab = true;
    },
    tabMetadata() {
      this.tab = false;
    },
    send() {
      if (this.tab) {
        this.queryA.id = this.query.id;
        this.query.documentHash = [];
        this.queryA.documentHash = this.query.documentHash;
        for (var i = 0; i < this.hashes.length; i++) {
          this.queryA.documentHash.push(this.hashes[i].documentHash);
        }
        this.backend.queryPublic(this.queryA).then((e) => {
          this.set_last_query(e);
          this.set_last_query_hashes(this.queryA.documentHash);
          this.set_query_header("confirmed_results");
          this.$router.push({ path: "/search/result" });
        });
      } else {
        this.query.id = "";
        this.query.documentHash = [];
        this.backend.query(this.query).then((e) => {
          this.set_last_query(e);
          this.set_last_query_hashes([]);
          this.set_query_header("search_results");
          this.$router.push({ path: "/search/result" });
        });
      }
    },
    hashesChange(hashes) {
      this.hashes = hashes;
    },
  },
};
</script>
<style>
a.search.active {
  color: black;
  border-bottom: 2px #2536d4 solid;
}

a.search:hover {
  text-decoration: none;
}
</style>
