<template>
  <div class="component mt-3 px-4">
    <h1 v-text="$ml.get('create_title')"></h1>
    <h3 class="mt-5" v-text="$ml.get('create_select_files')"></h3>
    <div class="card bg-white border-0 shadow rounded-0">
      <div class="card-body">
        <drag-drop-file @hashes_change="hashesChange"></drag-drop-file>
      </div>
    </div>
    <h3 class="mt-5" v-text="$ml.get('create_metadata')"></h3>
    <div class="card bg-light pb-4">
      <div class="card-body">
        <b-form-group
          id="fieldset-1"
          :label="$ml.get('create_metadata_entryname_title')"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="entry.name"
            :placeholder="$ml.get('create_metadata_entryname_placeholder')"
            trim
          ></b-form-input>
        </b-form-group>
        <b-form-group :label="$ml.get('create_metadata_visibility')">
          <b-form-radio-group
            id="btn-radios-1"
            v-model="entry.private"
            button-variant="outline-primary"
            size="sm"
            :options="[
              { text: $ml.get('create_metadata_visibility_priv'), value: true },
              { text: $ml.get('create_metadata_visibility_pub'), value: false },
            ]"
            buttons
            name="radios-btn-default"
          ></b-form-radio-group>
        </b-form-group>

        <b-row class="justify-content-between">
          <b-col lg="5">
            <b-form-group :label="$ml.get('create_metadata_id1')">
              <b-input-group>
                <b-input-group-prepend>
                  <b-form-input
                    class="rounded-0"
                    type="text"
                    v-model="entry.externalId1"
                    aria-label="Text input with checkbox"
                  ></b-form-input>
                </b-input-group-prepend>
                <b-form-input
                  type="text"
                  v-model="entry.externalId1Meaning"
                  :placeholder="$ml.get('detail_eid10')"
                  aria-label="Text input with checkbox"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col lg="5">
            <b-form-group :label="$ml.get('create_metadata_id2')">
              <b-input-group>
                <b-input-group-prepend>
                  <b-form-input
                    class="rounded-0"
                    v-model="entry.externalId2"
                    type="text"
                    aria-label="Text input with checkbox"
                  ></b-form-input>
                </b-input-group-prepend>
                <b-form-input
                  type="text"
                  v-model="entry.externalId2Meaning"
                  :placeholder="$ml.get('detail_eid20')"
                  aria-label="Text input with checkbox"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-form-group :label="$ml.get('create_metadata_anotation_title')">
          <b-form-textarea
            id="textarea"
            v-model="entry.annotation"
            :placeholder="$ml.get('create_metadata_anotation_placeholder')"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>
        <b-form-group :label="$ml.get('create_metadata_approval_title')">
          <b-form-checkbox
            id="checkbox-1"
            v-model="entry.approver"
            name="checkbox-1"
          >
            {{ $ml.get("create_metadata_approval_check") }}
          </b-form-checkbox>
        </b-form-group>
      </div>
    </div>
    <div class="text-right mb-5 pb-5 mt-2">
      <b-button
        v-b-modal.modal-confirm
        class="btn btn-success text-white"
        v-text="$ml.get('create_create')"
        @click="writing = false"
      ></b-button>
    </div>

    <!-- Modal Component -->
    <b-modal
      hide-header-close
      hide-footer
      header-bg-variant="primary"
      size="lg"
      centered
      id="modal-confirm"
      ref="modal-confirm"
      title="BootstrapVue"
    >
      <template slot="modal-title">
        <div class="px-3 py-2">
          <h3 class="text-white" v-text="$ml.get('create_confirm_title')"></h3>
          <h4
            class="text-white"
            v-text="$ml.get('create_confirm_subtitle')"
          ></h4>
        </div>
      </template>
      <div class="d-block">
        <h3 v-text="entry.name"></h3>
        <p
          v-text="
            entry.private ? $ml.get('entry_private') : $ml.get('entry_public')
          "
        ></p>
        <file-zone
          :url_editable="false"
          :removable="false"
          :hashes="this.entry.hashes"
        ></file-zone>
      </div>
      <div class="text-danger" v-if="entry.approver">
        Pin: {{ this.entry.approverPin }}
      </div>
      <div class="row mt-3">
        <div v-if="entry.externalId1.length > 0" class="col-6">
          <span
            class="text-muted"
            v-text="$ml.get('create_metadata_id1')"
          ></span
          ><br />
          <span v-text="entry.externalId1"></span
          ><span
            v-if="entry.externalId1Meaning.length > 0"
            class="text-muted font-weight-bold"
            v-text="'(' + entry.externalId1Meaning + ')'"
          ></span>
        </div>
        <div v-if="entry.externalId2.length > 0" class="col-6">
          <span
            class="text-muted"
            v-text="$ml.get('create_metadata_id2')"
          ></span
          ><br />
          <span v-text="entry.externalId2"></span>
          <span
            v-if="entry.externalId2Meaning.length > 0"
            class="text-muted font-weight-bold"
            v-text="'(' + entry.externalId2Meaning + ')'"
          ></span>
        </div>
      </div>
      <div class="mt-3">
        <span class="text-muted" v-text="$ml.get('approval_allow')"></span>
        <br />
        <span v-text="entry.approver ? $ml.get('yes') : $ml.get('no')"></span>
      </div>
      <div class="mt-3">
        <span
          class="text-muted"
          v-text="$ml.get('create_metadata_anotation_title')"
        ></span>
        <br />
        <span v-text="entry.annotation"></span>
      </div>
      <div class="mt-5 text-right">
        <b-button
          size="sm"
          variant="outline-secondary"
          @click="$refs['modal-confirm'].hide()"
        >
          {{ $ml.get("change_record") }}
        </b-button>
        <b-button
          size="sm"
          variant="success"
          @click="send()"
          :disabled="writing"
        >
          {{ $ml.get("submit_record") }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import DragDropFile from "./DragDropFile";
import FileZone from "./FileZone";
export default {
  methods: {
    ...mapActions(["set_newest", "set_entries_my"]),
    async send() {
      this.writing = true;
      if (!this.entry.approver) {
        this.entry.approverPin = "";
      }
      const e = await this.backend.createNewRecord(this.entry);
      // not needed thanks to new gtw settings
      // await new Promise(resolve => setTimeout(resolve, 3000))
      this.entry.approverPin =
        "" + Math.floor(Math.random() * (99999 - 10000) + 10000);
      this.$router.push({ path: "/entry/" + e.documentId });
      this.backend.myNewestEntries().then((e) => {
        if (e !== false) {
          this.set_newest(e);
        }
      });
      this.backend.getMyEntries().then((e) => {
        if (e !== false) {
          this.set_entries_my(e);
        }
      });
    },
    hashesChange(hashes) {
      this.entry.hashes = hashes;
    },
  },
  components: { DragDropFile, FileZone },
  data() {
    return {
      writing: false,
      entry: {
        name: "",
        hashes: [],
        private: false,
        approver: false,
        annotation: "",
        externalId1: "",
        externalId1Meaning: "",
        externalId2: "",
        externalId2Meaning: "",
        approverPin: "",
      },
    };
  },
  computed: mapGetters(["isLogged"]),
  created() {
    if (!this.isLogged) {
      this.$router.push("/");
    }
    this.entry.approverPin =
      "" + Math.floor(Math.random() * (99999 - 10000) + 10000);
  },
};
</script>
<style>
.icon-large {
  font-size: 5rem;
}
</style>
