<template>
  <div class="component mt-3 px-4">
    <div class="card bg-white rounded-0 border-0">
      <div class="card-body">
        <div class="text-right">
          <a
            class="btn btn-outline-primary text-white"
            v-text="$ml.get('compare')"
            @click="showCompare = !showCompare"
          ></a>
          <a
            v-if="
              this.$route.params.pin != null &&
              this.entry.approveStatus == 'waitingForApproval'
            "
            v-b-modal.modal-comment-confirm
            class="btn btn-outline-primary text-white ml-2"
            v-text="$ml.get('confirm')"
          ></a>
        </div>
        <drag-drop-file
          v-if="this.showCompare"
          @hashes_change="hashesChange"
          :url_editable="false"
          :hashes="fullHashes"
        ></drag-drop-file>
        <small class="text-muted font-semibold"
          ><span v-text="$ml.get('detail_timestamp')"></span> :
          <span v-text="formatDate(entry.timestamp)"></span> (<span
            v-text="$ml.get(entry.private ? 'entry_private' : 'entry_public')"
          ></span
          >)</small
        >
        <h1 class="font-weight-bold" v-text="entry.name"></h1>
        <div class="font-semibold">
          <span v-if="entry.private" class="text-primary"
            ><font-awesome-icon icon="lock" />
            <span v-text="$ml.get('entry_private')"></span>
          </span>
          <span v-else class="text-primary"
            ><font-awesome-icon icon="lock-open" />
            <span v-text="$ml.get('entry_public')"></span>
          </span>
          <span class="ml-3"
            ><span v-text="$ml.get('entry_id') + ':'"></span>
            <span v-text="entry.id" class="bg-semidark p-1 rounded"></span
          ></span>
          <span class="ml-3 text-success"
            ><font-awesome-icon icon="circle" />
            <span v-text="$ml.get('detail_active')"></span
          ></span>
          <span
            class="text-muted"
            v-if="
              entry.expirationDate != null && entry.expirationDate.length > 0
            "
            v-text="
              '(' +
              $ml.get('expires') +
              ' ' +
              formatDate(entry.expirationDate) +
              ')'
            "
          ></span>
        </div>
        <div
          class="mt-4 text-muted font-semibold pb-3"
          v-text="$ml.get('detail_files')"
        ></div>

        <file-zone
          :removable="false"
          :url_editable="false"
          :hashes="entry.hashes"
          :compareHashes="compareHashes"
        ></file-zone>

        <b-row class="mt-5">
          <b-col sm="6">
            <small
              v-text="$ml.get('detail_author')"
              class="font-semibold text-muted"
            ></small
            ><br />
            <span
              v-text="
                !entry.parentOrgId || entry.parentOrgId === entry.orgId
                  ? entry.authorId + ' / ' + entry.orgId
                  : entry.authorId +
                    ' / ' +
                    entry.parentOrgId +
                    '/' +
                    entry.orgId
              "
              class="large"
            ></span>
          </b-col>
          <b-col sm="6">
            <small
              v-text="$ml.get('detail_node')"
              class="font-semibold text-muted"
            ></small
            ><br />
            <span v-text="entry.nodeId" class="large"></span>
          </b-col>
        </b-row>
        <b-row class="mt-5">
          <b-col sm="6">
            <small
              v-text="$ml.get('detail_eid1')"
              class="font-semibold text-muted"
            ></small
            ><br />
            <span v-text="entry.externalId1" class="large"></span>
            <span
              class="text-secondary"
              v-text="'(' + entry.externalId1Meaning + ')'"
            ></span>
          </b-col>
          <b-col sm="6">
            <small
              v-text="$ml.get('detail_eid2')"
              class="font-semibold text-muted"
            ></small
            ><br />
            <span v-text="entry.externalId2" class="large"></span>
            <span
              class="text-secondary"
              v-text="'(' + entry.externalId2Meaning + ')'"
            ></span>
          </b-col>
        </b-row>
        <div class="mt-5">
          <small
            class="text-muted font-semibold"
            v-text="$ml.get('detail_anotation')"
          ></small>
        </div>
        <p v-text="entry.annotation"></p>
        <div class="mt-5">
          <small
            class="text-muted font-semibold"
            v-text="$ml.get('detail_comment_process')"
          ></small>
        </div>
        <p v-text="$ml.get('approvestatus_' + this.entry.approveStatus)"></p>
        <div v-if="this.$route.params.pin == null && this.entry.approverPin">
          <div class="mt-5">
            <small
              class="text-muted font-semibold"
              v-text="$ml.get('comment_pin')"
            ></small>
          </div>
          <p class="text-danger" v-text="this.entry.approverPin"></p>
        </div>
        <br />
        <table
          class="table"
          v-if="
            this.entry.approveStatus !== 'waitingForApproval' &&
            this.entry.approveStatus !== 'withoutApproval'
          "
        >
          <tr>
            <td>{{ $ml.get("approve_by") }}</td>
            <td v-if="!entry.approverParentOrgId && !entry.approverOrgId">
              {{ this.entry.approverId }}
            </td>
            <td
              v-else
              v-text="
                entry.approverParentOrgId === entry.approverOrgId
                  ? entry.approverId + ' / ' + entry.approverOrgId
                  : entry.approverId +
                    ' / ' +
                    entry.approverParentOrgId +
                    '/' +
                    entry.approverOrgId
              "
            ></td>
          </tr>
          <tr v-if="!!entry.approveNodeId">
            <td>{{ $ml.get("approve_node") }}</td>
            <td>{{ this.entry.approveNodeId }}</td>
          </tr>
          <tr>
            <td>{{ $ml.get("approve_at") }}</td>
            <td>
              {{
                new Date(this.entry.approveTimestamp).toLocaleDateString("cs")
              }}
            </td>
          </tr>
          <tr>
            <td>{{ $ml.get("approve_comment") }}</td>
            <td>{{ this.entry.approveComment }}</td>
          </tr>
        </table>
      </div>
    </div>

    <b-modal
      id="modal-comment-confirm"
      ref="modal-comment-confirm"
      :title="$ml.get('comment_t')"
      hide-footer
    >
      <b-form-group :label="$ml.get('comment')">
        <b-form-textarea
          id="textarea"
          v-model="comment_text"
          rows="3"
          max-rows="6"
        ></b-form-textarea>
      </b-form-group>
      <div class="text-right">
        <a
          class="btn btn-outline-danger"
          @click="sendApproveN"
          v-text="$ml.get('comment_notapproved')"
        ></a>
        <a
          class="btn btn-outline-warning"
          @click="sendApproveW"
          v-text="$ml.get('comment_approved_with')"
        ></a>
        <a
          class="btn btn-outline-primary"
          @click="sendApprove"
          v-text="$ml.get('comment_approved')"
        ></a>
      </div>
    </b-modal>
  </div>
</template>
<script>
import FileZone from "./FileZone";
import DragDropFile from "./DragDropFile";
import { mapGetters, mapActions } from "vuex";
export default {
  components: { FileZone, DragDropFile },
  computed: mapGetters(["email"]),
  created() {
    if (this.$route.params.pin != null) {
      this.backend
        .viewToApprove(this.$route.params.id, this.$route.params.pin)
        .then((res) => {
          this.entry = res;
        });
    } else {
      this.backend.getDetail(this.$route.params.id).then((res) => {
        this.entry = res;
      });
    }
  },
  methods: {
    ...mapActions(["set_approved_newest", "set_entries_my_approved"]),
    formatDate(date) {
      var d = new Date(date);
      var month = (d.getUTCMonth() < 10 ? "0" : "") + (d.getUTCMonth() + 1);
      var day = (d.getUTCDate() < 10 ? "0" : "") + d.getUTCDate();
      var year = d.getUTCFullYear();
      var hour = (d.getUTCHours() < 10 ? "0" : "") + d.getUTCHours();
      var minute = (d.getUTCMinutes() < 10 ? "0" : "") + d.getUTCMinutes();

      return (
        [year, month, day].join("-") + " " + [hour, minute].join(":") + " (UTC)"
      );
    },
    hashesChange(hashes) {
      this.fullHashes = hashes;
      this.compareHashes = [];
      for (var i = 0; i < hashes.length; i++) {
        this.compareHashes.push(hashes[i].documentHash);
      }
    },
    sendApprove() {
      this.$refs["modal-comment-confirm"].hide();
      this.backend
        .approve(this.$route.params.id, this.$route.params.pin, {
          status: "approved",
          comment: this.comment_text,
        })
        .then((e) => {
          this.backend.getDetail(this.$route.params.id).then((res) => {
            this.entry = res;
          });
          this.backend.myNewestApproved().then((e) => {
            if (e !== false) {
              this.set_approved_newest(e);
            }
          });
          this.backend.getMyApprovedEntries().then((e) => {
            if (e !== false) {
              this.set_entries_my_approved(e);
            }
          });
        });
    },
    sendApproveN() {
      this.$refs["modal-comment-confirm"].hide();
      this.backend
        .approve(this.$route.params.id, this.$route.params.pin, {
          status: "notApproved",
          comment: this.comment_text,
        })
        .then((e) => {
          this.backend.getDetail(this.$route.params.id).then((res) => {
            this.entry = res;
          });
          this.backend.myNewestApproved().then((e) => {
            if (e !== false) {
              this.set_approved_newest(e);
            }
          });
          this.backend.getMyApprovedEntries().then((e) => {
            if (e !== false) {
              this.set_entries_my_approved(e);
            }
          });
        });
    },
    sendApproveW() {
      this.$refs["modal-comment-confirm"].hide();
      this.backend
        .approve(this.$route.params.id, this.$route.params.pin, {
          status: "approvedWithComments",
          comment: this.comment_text,
        })
        .then((e) => {
          this.backend.getDetail(this.$route.params.id).then((res) => {
            this.entry = res;
          });
          this.backend.myNewestApproved().then((e) => {
            if (e !== false) {
              this.set_approved_newest(e);
            }
          });
          this.backend.getMyApprovedEntries().then((e) => {
            if (e !== false) {
              this.set_entries_my_approved(e);
            }
          });
        });
    },
  },
  data() {
    return {
      comment_text: "",
      compareHashes: [],
      fullHashes: [],
      showCompare: false,
      entry: {
        id: "",
        externalId1: "",
        externalId1Meaning: "",
        externalId2: "",
        externalId2Meaning: "",
        name: "",
        private: true,
        annotation: "",
        timestamp: "",
        company: "",
        authorId: "",
        node: "",
        expirationDate: "",
        hashes: [
          {
            timestamp: "",
            fileName: "",
            documentUrl: "",
            hashFunction: "SHA-256",
            documentHash: "",
          },
        ],
        approverId: "",
        approverOrgId: "",
        approverParentOrgId: "",
        approveNodeId: "",
        approveTimestamp: "",
        approveComment: "",
        approveStatus: "waitingForApproval",
        approverPin: "",
      },
    };
  },
};
</script>
<style>
.large {
  font-size: 1.4em;
}
</style>
